<!-- 校史故事 -->
<template>
  <div>
    <TabPageLayout activeCode="yujianyizhong">
      <!-- 校史故事 -->
      <div class="container history">
        <!-- util-bin -->
        <div class="div_mod">
          <!-- 栏目简介 -->
          <div class="div_text" v-html="activeTabBz"></div>
        </div>
        <div class="div_r">
          <div class="div_px">
            <span
              class="s2"
              :class="{
                on: sortType === 'desc',
              }"
              @click="handleSortClick()"
            ></span>
          </div>
          <ul>
            <HistoryCard
              class="history-card"
              v-for="article in articles"
              :key="article.id"
              :article="article"
            ></HistoryCard>
          </ul>
        </div>
      </div>
    </TabPageLayout>
  </div>
</template>

<script>
import TabPageLayout from './components/TabPageLayout.vue'
import HistoryCard from './components/HistoryCard.vue'
import { mapState } from 'vuex'
import portalApi from '../../../lvya-common/src/api/eos/portal'
import { throttle } from '../../../lvya-common/src/utils/DebounceThrottle.js'

export default {
  components: {
    TabPageLayout,
    HistoryCard,
  },
  data() {
    return {
      sortType: 'asc',
      articles: [],
    }
  },
  computed: {
    ...mapState({
      nav: (state) => state.yzsyStore.nav,
    }),
    navTabs() {
      return (
        this.nav
          ?.find((item) => item.code === 'yujianyizhong')
          ?.stypes?.map((nav) => ({
            id: nav.id,
            name: nav.title,
            path: nav.tzurl,
            bz: nav.bz,
          })) || []
      )
    },
    activeTabId() {
      return this.navTabs?.find((item) => item.path === this.$route.path)?.id
    },
    activeTabBz() {
      console.log(
        this.navTabs?.find((item) => item.path === this.$route.path),
        'activeTabBz'
      )
      return this.navTabs?.find((item) => item.path === this.$route.path)?.bz
    },
  },
  methods: {
    handleSortClick() {
      this.sortType === 'asc'
        ? (this.sortType = 'desc')
        : (this.sortType = 'asc')
      this.sortArticle()
    },
    sortArticle() {
      this.articles = this.articles.sort((a, b) => {
        const aTime = new Date(a.ctime).getTime()
        const bTime = new Date(b.ctime).getTime()
        console.log('排序')
        if (this.sortType === 'asc') {
          return aTime - bTime
        } else {
          return bTime - aTime
        }
      })
    },
    async getArticles() {
      // const portalSchoolInfo = JSON.parse(
      //   localStorage.getItem('portalSchoolInfo')
      // )
      let reqData = {
        noSchoolId: true,
        schoolId: '7',
        pageNum: 1,
        pageSize: 999,
        typeId: this.activeTabId,
        isContent: 'Y',
        applyState: 'Y',
      }
      let resData = await portalApi.getArtsByPage(reqData)
      console.log({ resData }, 'history')
      this.articles = resData.list
      this.total = resData.total
      this.sortArticle()
    },

    handleScroll() {
      // 查看该元素是否距离顶部最近，在可视区内并且距离顶部最近的元素添加 current 类名
      const historyCards = document.querySelectorAll('.history-card')
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      let minDistance = Infinity
      let minIndex = -1
      historyCards.forEach((card, index) => {
        const cardTop = card.offsetTop
        const distance = Math.abs(cardTop - scrollTop)
        if (distance < minDistance) {
          minDistance = distance
          minIndex = index
        }
      })
      if (minIndex !== -1) {
        historyCards.forEach((card, index) =>
          card.classList[index === minIndex ? 'add' : 'remove']('current')
        )
      }
    },
  },
  mounted() {
    this.getArticles()
    window.addEventListener('scroll', throttle(this.handleScroll))
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="less" scoped>
@import "./style/about.css";
</style>

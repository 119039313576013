<template>
  <li>
    <span class="time">{{ new Date(article.ctime).getFullYear() }}</span>
    <h3>{{ article.title }}</h3>
    <div
      class="div_more"
      :style="{
        height: isShowMore ? 'auto' : '',
      }"
      v-if="isShowMore"
    >
      <p>
        {{ article.des }}
      </p>
      <p v-html="article.content"></p>
    </div>
    <a @click="isShowMore = !isShowMore" v-if="!isShowMore"> 查看更多 > </a>
  </li>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isShowMore: true,
    }
  },
}
</script>

<style lang="less" scoped>
@import "../style/about.css";
</style>
